<template>
  <div v-if="details.loaded">
    <b-button
      v-if="hasLevel"
      v-b-modal.gamification
      variant="info"
      class="d-flex align-items-center"
    >
      <img
        v-if="currentLevelImage"
        style="height: 40px; width: auto"
      >
      <b-icon
        v-else
        font-scale=".90"
        icon="trophy-fill"
        color="gold"
        class="mr-2"
      />
      <span>{{ currentLevelName }}</span>
    </b-button>
    <b-button
      v-else-if="details.profile"
      id="update-profile"
      v-b-tooltip.hover
      style="padding: 5px"
      class="d-flex align-items-center"
      :variant="profileIsComplete ? 'success' : 'info'"
      @click="onToggleGamificationDetails"
    >
      <b-icon
        v-if="profileIsComplete"
        font-scale=".90"
        icon="trophy-fill"
        color="gold"
        class="mr-2"
      />
      Profile Complete: {{ profileCompletePercent }} %
    </b-button>
    <b-tooltip
      target="update-profile"
      placement="top"
      variant="info"
      :show="showProfileCompleteTooltip"
    >
      <span v-html="profileCompleteMessage" />
    </b-tooltip>
    <b-modal
      centered
      ok-only
      hide-header
      ok-title="Done!"
      size="sm"
      button-size="lg"
      ref="profileComplete"
      title="Profile Complete!"
      footer-class="d-flex align-items-center justify-content-center"
      :header-bg-variant="profileComplete.color"
      :header-text-variant="profileComplete.textColor"
      :body-bg-variant="profileComplete.color"
      :body-text-variant="profileComplete.textColor"
      :footer-bg-variant="profileComplete.color"
      :footer-text-variant="profileComplete.textColor"
    >
      <b-row
        align-content="center"
        align-v="center"
        align-h="center"
        style="flex-direction: column"
        class="text-center"
      >
        <b-col>
          <h1 class="mt-3">
            You did it!
          </h1>
        </b-col>
        <b-col class="mt-3">
          <b-icon
            font-scale="10"
            icon="trophy-fill"
            animation="fade"
            color="gold"
          />
        </b-col>

        <b-col>
          <p class="mt-3">
            Great job on completing your profile!
          </p>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      centered
      ok-only
      hide-header
      ok-title="Done!"
      size="sm"
      button-size="lg"
      ref="newBadge"
      title="You've leveled up!"
      footer-class="d-flex align-items-center justify-content-center"
      :header-bg-variant="profileComplete.color"
      :header-text-variant="profileComplete.textColor"
      :body-bg-variant="profileComplete.color"
      :body-text-variant="profileComplete.textColor"
      :footer-bg-variant="profileComplete.color"
      :footer-text-variant="profileComplete.textColor"
      @hidden="details.recent_badge = null"
    >
      <b-row
        align-content="center"
        align-v="center"
        align-h="center"
        style="flex-direction: column"
        class="text-center"
      >
        <b-col>
          <h1 class="mt-3">
            You did it!
          </h1>
        </b-col>
        <b-col class="mt-3">
          <b-icon
            font-scale="10"
            icon="trophy-fill"
            animation="fade"
            color="gold"
          />
        </b-col>

        <b-col>
          <p class="mt-3">
            You have just acquired the <strong>{{ currentLevelName }}</strong> badge by earning <strong>{{ $user.reputation }} points</strong>!
          </p>
          <p>{{ currentLevelDescription }}</p>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      centered
      ok-only
      ok-title="Got it!"
      size="lg"
      button-size="lg"
      id="gamification"
      ref="gamification"
      title="Gamification on Ask Dr. Figo!"
      footer-class="d-flex align-items-center justify-content-center"
      :header-bg-variant="gamificationDetails.color"
      :header-text-variant="gamificationDetails.textColor"
      :body-bg-variant="gamificationDetails.color"
      :body-text-variant="gamificationDetails.textColor"
      :footer-bg-variant="gamificationDetails.color"
      :footer-text-variant="gamificationDetails.textColor"
    >
      <b-row
        align-content="center"
        align-v="center"
        align-h="center"
        style="flex-direction: column"
      >
        <b-col>
          <h1 class="mt-3">
            Hey {{ $user.first_name }}!
          </h1>
        </b-col>
        <b-col class="mt-3">
          <b-tabs
            content-class="mt-3"
            pills
            fill
          >
            <b-tab
              title="Your Progress"
              active
            >
              <b-alert
                show
                v-if="!details.level"
              >
                You haven't earned a badget yet!
              </b-alert>
              <div v-else>
                <b-button>
                  <b-icon
                    v-if="profileIsComplete"
                    font-scale=".90"
                    icon="trophy-fill"
                    color="gold"
                    class="mr-2"
                  />Current Badge: {{ currentLevelName }}
                </b-button>
                <p v-html="details.level.description" />
              </div>
              <div v-if="details.next_level">
                <span>Points: <strong>{{ $user.reputation }} / {{ details.next_level.max_points }}</strong></span>
                <b-progress
                  class="mb-3"
                  striped
                  animated
                  show-value
                  show-progress
                  height="15px"
                  :value="details.level_complete_percent || 0"
                  :max="100"
                />
                <b-button variant="primary">
                  Next Badge: <b-badge variant="light">
                    {{ details.next_level.name }}
                  </b-badge>
                </b-button>
                <p v-html="details.next_level.description" />
                <h4 class="mt-3">
                  Earn {{ nextLevel.tier.max_points - nextLevel.tier.min_points }} points by doing the following
                </h4>
                <b-list-group>
                  <b-list-group-item
                    v-for="(point, index) in nextLevelPoints"
                    :key="index"
                    href="#"
                    class="d-flex justify-content-between"
                  >
                    {{ point.message }}
                    <b-badge>{{ point.points }} points</b-badge>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </b-tab>
            <b-tab title="Earn Points">
              <h3>Earn points when you</h3>
              <b-list-group style="max-height: 60vh; overflow-y: auto">
                <b-list-group-item
                  v-for="(point, index) in details.points"
                  :key="index"
                  href="#"
                  class="d-flex justify-content-between"
                >
                  {{ point.message }}
                  <b-badge>{{ point.points }} points</b-badge>
                </b-list-group-item>
              </b-list-group>
            </b-tab>
            <b-tab title="Earn Badges">
              <h3>Earn Badges when you reach milestones</h3>
              <b-list-group style="max-height: 60vh; overflow-y: auto">
                <b-list-group-item
                  v-for="(level, index) in details.levels"
                  :key="index"
                  href="#"
                  class="d-flex justify-content-between flex-column"
                >
                  <div class="d-flex justify-content-between">
                    <h5>{{ level.name }}</h5>
                    <b-badge>
                      <template v-if="level.min_points > 0">
                        {{ level.min_points }} -
                      </template>{{ level.max_points }} points
                    </b-badge>
                  </div>
                  <p v-html="level.description" />
                </b-list-group-item>
              </b-list-group>
            </b-tab>
            <b-tab
              title="History"
              @click="onGetGamificationHistory"
            >
              <h3>Points and Badges You've Earned</h3>
              <b-list-group style="max-height: 60vh; overflow-y: auto">
                <b-list-group-item
                  v-for="(item, index) in history.data"
                  :key="index"
                  href="#"
                  class="d-flex justify-content-between"
                >
                  <div>
                    <span>
                      {{ item.name }}
                    </span>
                    <b-badge
                      v-if="item.type === 'point'"
                      variant="success"
                    >
                      {{ item.point }} points
                    </b-badge>
                    <b-badge
                      v-if="item.type === 'badge'"
                      style="background-color: gold"
                    >
                      Badge!
                    </b-badge>
                  </div>
                  <span>{{ formatDate(item.created_at) }}</span>
                </b-list-group-item>
              </b-list-group>
              <b-pagination
                v-if="history.next_page_url"
                v-model="historyPage"
                align="center"
                class="mt-3"
                aria-controls="gamification-history"
                :total-rows="history.total"
                :per-page="10"
                @change="onGetGamificationHistory"
              />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  props: {
    details: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      profileComplete: {
        color: "success",
        textColor: "white"
      },
      gamificationDetails: {
        color: "dark",
        textColor: "white"
      },
      profileWascomplete: false,
      historyPage: 1,
      history: { data: [] }
    };
  },
  watch: {
    recentLevel: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        console.log("[Gamification]: Recent bagde changed", oldVal, newVal);
        if (newVal instanceof Object) {
          console.log("[Gamification]: Recent badge earned!");
          this.onToggleNewBadgeEarned();
        }
      }
    }
  },
  computed: {
    pointsMapped() {
      return this.details?.points_mapped || {};
    },
    recentLevel() {
      return this.details?.recent_badge || null;
    },
    nextLevel() {
      return this.pointsMapped[this.details.next_level?.name] || { points: [] };
    },
    nextLevelPoints() {
      return this.nextLevel.points;
    },
    currentLevelName() {
      return this.details.level?.name || "";
    },
    currentLevelImage() {
      return null;
    },
    currentLevelDescription() {
      return this.details.level?.description || "";
    },
    hasLevel() {
      return this.details.level && this.details.level instanceof Object;
    },
    profileCompletePercent() {
      return this.details.profile?.complete_percent || 0;
    },
    profileIsComplete() {
      return this.details.profile?.is_complete === true;
    },
    showProfileCompleteTooltip() {
      return this.profileCompletePercent < 100;
    },
    profileCompleteMessage() {
      return ([
        {
          range: [0, 33],
          message: "Let's setup your profile"
        },
        {
          range: [34, 66],
          message: "Almost there! Click here to complete your profile"
        },
        {
          range: [64, 99],
          message: "One more thing to complete your profile!"
        },
        {
          range: [100, 100],
          message: `Your profile is complete! You need ${this.details.points_required_for_next_level} points to get your <b>${this.details.next_level?.name}</b> badge! Click the button above to learn how`
        }
      ].find(
        (m) =>
          this.details.profile?.complete_percent >= m.range[0] &&
          this.details.profile?.complete_percent <= m.range[1]
      ) || { message: "" })["message"].replace(
        "{{ percent }}",
        this.details.profile?.complete_percent || 0
      );
    }
  },
  methods: {
    ...mapActions(["getGamificationHistory"]),
    onToggleProfileComplete() {
      this.$refs["profileComplete"].toggle(`#profile-complete`);
    },
    onToggleNewBadgeEarned() {
      this.$nextTick(() => {
        this.$refs["newBadge"].toggle(`#badge-earned`);
      });
    },
    onToggleGamificationDetails() {
      if (this.profileIsComplete) {
        this.$refs["gamification"].toggle(`#gamification`);
      } else {
        this.$router.push({ name: "Profile" });
      }
    },
    onGetGamificationHistory() {
      this.$apiService
        .getGamificationHistory({
          page: this.history.current_page + 1
        })
        .then((result) => {
          this.history = result.data;
        });
    },
    formatDate(date) {
      return date ? moment(date).fromNow() : "";
    }
  },
  mounted() {
    // this.onToggleProfileComplete();
    // this.onToggleGamificationDetails();
    // this.onToggleNewBadgeEarned();
    this.profileWascomplete = this.profileIsComplete;
    this.$eventBus.$on("profile-is-complete", () => {
      this.onToggleProfileComplete();
    });
  }
};
</script>
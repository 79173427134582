import Vue from 'vue';
import { mapGetters } from 'vuex';
import App from './App.vue';
import './registerServiceWorker';
import { router, store } from './router';
import VueAnalytics from 'vue-analytics';
import moment from 'moment';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

import APIService from './services/apiService';
import ToasterService from './services/toasterService';
import { EventBus } from './services/event-bus';

import ClaimButton from '@/components/ClaimButton';
import FlagButton from '@/components/FlagButton';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

import axios from 'axios';
axios.defaults.baseURL = process.env.VUE_APP_API_URI;

// Interceptor
axios.interceptors.request.use(
	function (config) {
		// Do something before request is sent
		config.headers.Authorization = `Bearer ${localStorage.getItem(
			'accessToken'
		)}`;
		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	function (response) {
		// Do something with response data
		return response;
	},
	function (error) {
		// Do something with response error
		if (error.response && error.response.status == 401) {
			console.log('[Main]: 401 received. Logging out');
			localStorage.clear();
			window.location.href = '/';
		}

		return Promise.reject(error);
	}
);
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(
	'accessToken'
)}`;

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(Loading, {
	color: '#ec5134',
});

//and then use it in main.js
Vue.use(VueAnalytics, {
	id: process.env.VUE_APP_GTAG,
	router,
});

// Add global properties
Vue.prototype.$apiService = new APIService(store);
Vue.prototype.$toastMessage = new ToasterService();
Vue.prototype.$eventBus = EventBus;

Vue.mixin({
	methods: {
		parseJSON(value) {
			let result = {};
			try {
				result = JSON.parse(value);
			} catch (error) {
				result = {};
			}
			return result;
		},
		checklistHandler() {
			this.$router.push({ name: 'Checklist' });
		},
	},
	computed: {
		...mapGetters(['$user', '$selectedCountry']),
	},
});

Vue.filter('formatDate', function (value) {
	if (value) {
		let DateToUtc = moment.utc(value).toDate();
		let DateToLocal = moment(DateToUtc).local().format('MM/DD/YYYY HH:mm:ss');
		return DateToLocal;
	}
});

// Components
Vue.component('FlagButton', FlagButton);
Vue.component('ClaimButton', ClaimButton);

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');

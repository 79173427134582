<template>
  <div class="container">
    <div class="user-login-wrap row p-3">
      <div class="card-wrap col-md-7 mx-auto add-guidelines-wrap">
        <h1 class="guidelines-title">
          Ask Dr FIGO Guidelines:
        </h1>
        <hr class="guidelines-break">
        <p>
          <b>
            When we’re talking to our patients on ADF, keep in mind the
            following things:
          </b>
        </p>
        <ul>
          <li>
            <b>Friendly and simple tone</b>
            <br>
            We want our tone to be friendly and not overly clinical (where
            possible). We are anticipating a young audience, so medical terms
            and jargon can make answers hard to understand.
          </li>
          <li>
            <b>Information not advice</b>
            <br>
            For legal reasons, we must make it clear that these answers are
            informational and not medical advice. Direct them to the Resources
            page if you think they need to see a provider.
          </li>
          <li><b>Offer non-judgemental information</b></li>
          <li>
            <b>Timeliness</b>
            <br>
            Time is an important factor. Try to answer questions every day. We
            want people to trust that their questions will be answered.
          </li>
          <li>
            <b>Avoid self promotion</b>
            <br>
            Ask Dr FIGO cannot promote specific medical practices. Please offer
            broad suggestions to clinics/hospitals, and do not offer to see the
            patient in person.
          </li>
          <li>
            <b>Remain anonymous</b>
            <br>
            Please do not give out your own personal information (name, phone,
            email, location). We care about both our doctors’ privacy and our
            site users’ privacy
          </li>
        </ul>
        <hr class="guidelines-break">
        <div class="row">
          <div class="col-md-8">
            <p class="m-0">
              If you have any questions/comments/concerns:
              <br>
              <span style="color:#53bbb4">feedback@askdrfigo.org</span>
            </p>
          </div>
          <div class="col-md-4">
            <a
              class="drbtn btn btn-outline-primary"
              @click="onSubmit()"
              style="width: 100%"
            >Next</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CheckList",
  methods: {
    onSubmit() {
      // let expertiseCount = localStorage.getItem("expertiseCount");
      // if(expertiseCount == 0){
      //   this.$router.push("/profile");
      // }
      // else
      if (this.$route.query.redirect == undefined) {
        this.$router.push({ name: "Questions" });
      } else {
        this.$router.push(this.$route.query.redirect);
      }
    }
  }
};
</script>

<style scoped>
.guidelines-title {
  font-size: 18px;
  color: #53bbb4;
}

.guidelines-break {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-color: #53bbb4;
}

.add-guidelines-wrap {
  padding: 25px;
}
</style>

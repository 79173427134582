<template>
  <main class="main-contant">
    <div class="clearfix">
      <h1 class="page-title float-left">
        profile
      </h1>
    </div>
    <b-card
      v-if="!$gamification.profile.is_complete"
      bg-variant="primary"
      text-variant="white"
      class="mb-4 page-content"
    >
      <b-card-text>
        <h2>Hey {{ $user.first_name }}!</h2>
        <p>You're almost done completing up your profile! Just a few more things left.</p>
        <b-list-group
          flush
          class="mt-3"
        >
          <b-list-group-item
            v-for="(status, item) in $gamification.profile.complete_items"
            class="d-flex justify-content-between align-items-center"
            :variant="status === true ? 'success' : 'light'"
            :key="item"
          >
            {{ item }}
            <b-icon
              icon="check-circle-fill"
              font-scale="1.5"
              v-if="status"
            />
          </b-list-group-item>
        </b-list-group>
      </b-card-text>
    </b-card>
    <div class="page-content">
      <div class="card-wrap p-3 p-lg-5 mb-4">
        <form action="">
          <div class="profile_wrap">
            <div class="row">
              <div class="col-xl-9">
                <div class="form-group">
                  <label for="">profile picture</label>
                  <div class="input-wrap">
                    <div class="profile-wrap">
                      <img
                        alt="profile"
                        ref="avatar"
                        :src="$user.avatar || defaultAvatar"
                      >
                    </div>
                    <div class="profile-wrap">
                      <div class="upload-btn">
                        <i class="fa fa-picture-o" />Upload
                        <input
                          type="file"
                          accept=".jpg, .jpeg, .png"
                          @change="fileChangeEvent($event)"
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="">Name</label>
                  <div class="input-wrap">
                    <input
                      type="text"
                      placeholder="Name"
                      class="form-control"
                      v-model="$user.name"
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label for="">Email</label>
                  <div class="input-wrap">
                    <input
                      type="email"
                      placeholder="Email"
                      class="form-control"
                      v-model="$user.email"
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label for="">Password</label>
                  <div class="input-wrap">
                    <button
                      class="btn drbtn btn-outline-primary"
                      type="button"
                      @click="onPasswordChangeHandler()"
                    >
                      Change Password
                    </button>
                  </div>
                </div>
                <div
                  class="form-group"
                  v-if="isChangePassword"
                >
                  <label for="">Current Password</label>
                  <div class="input-wrap">
                    <input
                      type="password"
                      placeholder="Current Password"
                      class="form-control"
                      v-model="$user.current_password"
                    >
                  </div>
                </div>
                <div
                  class="form-group"
                  v-if="isChangePassword"
                >
                  <label for="">New Password</label>
                  <div class="input-wrap">
                    <input
                      type="password"
                      placeholder="New Password"
                      class="form-control"
                      v-model="$user.new_password"
                    >
                  </div>
                </div>
                <div
                  class="form-group"
                  v-if="isChangePassword"
                >
                  <label for="">Confirm Password</label>
                  <div class="input-wrap">
                    <input
                      type="password"
                      placeholder="Confirm Password"
                      class="form-control"
                      v-model="$user.confirm_password"
                    >
                  </div>
                </div>
                <div class="form-group text-right">
                  <b-button
                    class="px-5 mx-2 my-3 drbtn"
                    @click="onSubmit()"
                  >
                    Save
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="card-heading">
          <h3>My Expertise</h3>
        </div>
        <!-- v-model="$user.expertise" -->
        <VSelect
          search-placeholder="Search Bar"
          search-not-found="No expertise found!"
          default-title="Select Expertise"
          class="expert-drp"
          :options="expertiseList"
          :disabled="disabled"
          :searchable="searchable"
          @input="setSelected"
        />
        <div class="mt-3 expertise-list">
          <span
            v-for="(expertise, index) in $user.expertise"
            :key="index"
          >
            {{ expertise.expertise_name }}
            <span
              @click="remove(index, expertise.id)"
              class="removeBtn"
            >
              <img src="../../assets/images/xmark-solid.svg">
            </span>
          </span>
          <!-- <strong>{{ $user.expertise }}</strong> -->
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Vue from "vue";
import VSelect from "vue-select-picker-bootstrap";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Profile",
  components: {
    VSelect,
  },
  data() {
    return {
      copyProfileInfo: {},
      isChangePassword: false,
      defaultAvatar: null,
      expertiseList: [],
      disabled: false,
      searchable: true,
      profileWasComplete: true,
    };
  },
  computed: {
    ...mapGetters(["$user", "$gamification"]),
    userExpertise() {
      return (this.$user.expertise || []).map((e) => e.id);
    },
  },
  methods: {
    ...mapActions(["loadGamification"]),
    remove(index, id) {
      this.removeDoctorExpertise(id);
      this.$delete(this.$user.expertise, index);
      return true;
    },
    expertiseExists(name) {
      return this.$user.expertise.some(function (el, index) {
        if (el.value === name) {
          return true;
        } else {
          return false;
        }
      });
    },
    setSelected(data) {
      console.log(this.$user.id);

      // if not exit then remove
      if (!this.expertiseExists(data.value)) {
        // call insert api Here
        this.addDoctorExpertise(data);
      }
    },
    getProfile() {
      let loader = Vue.$loading.show();

      this.$apiService
        .getProfile()
        .then((response) => {
          loader.hide();

          let res = response.data.data;

          if (response.status == 200) {
            this.$user = res;
            // get all expertise of doctor;
            let doctorId = res.id;
            this.prepareExpertise(res.expertise);
            // this.getDoctorExpertise(doctorId);

            this.copyProfileInfo = this.parseJSON(JSON.stringify(this.$user));
          }
        })
        .catch((e) => {
          loader.hide();
          this.$user = { avatar: null };
        });
    },
    onSubmit() {
      if (this.$user.name == undefined || this.$user.name == "") {
        this.$toastMessage.error("Name is required");
        return false;
      }

      if (this.$user.email == undefined || this.$user.email == "") {
        this.$toastMessage.error("Email is required");
        return false;
      }

      if (!this.$apiService.validateEmail(this.$user.email)) {
        this.$toastMessage.error("Please enter valid email");
        return false;
      }

      if (this.isChangePassword) {
        if (
          this.$user.current_password == undefined ||
          this.$user.current_password == ""
        ) {
          this.$toastMessage.error("Current password is required");
          return false;
        }

        if (this.$user.new_password == undefined || this.$user.new_password == "") {
          this.$toastMessage.error("New password is required");
          return false;
        }
        if (
          this.$user.confirm_password == undefined ||
          this.$user.confirm_password == ""
        ) {
          this.$toastMessage.error("Confirm password is required");
          return false;
        }

        if (
          this.$user.new_password &&
          this.$user.confirm_password &&
          this.$user.new_password != this.$user.confirm_password
        ) {
          this.$toastMessage.error("Confirm password and password must match");
          return false;
        }
      }

      let loader = Vue.$loading.show();

      let sendData = {
        name: this.$user.name,
        email: this.$user.email,
      };

      if (this.$user.new_password) {
        sendData.new_password = this.$user.new_password;
      }

      if (this.$user.current_password) {
        sendData.current_password = this.$user.current_password;
      }

      this.$apiService
        .updateProfile(sendData)
        .then((response) => {
          loader.hide();
          let res = response.data;
          console.log("Response", res);

          this.$eventBus.$emit("profile-name-change-event", this.$user.name);
          this.isChangePassword = false;
          this.$user.current_password = "";
          this.$user.new_password = "";
          this.$user.confirm_password = "";
          this.onLoadGamification();
          console.log("Updated profile");
          this.$toastMessage.success(res.message);
        })
        .catch((e) => {
          loader.hide();
          this.$toastMessage.error(e.response.data.data.message);
        });
    },
    onPasswordChangeHandler() {
      this.isChangePassword = !this.isChangePassword;
      this.$user.current_password = "";
      this.$user.new_password = "";
      this.$user.confirm_password = "";
    },
    fileChangeEvent(event) {
      let loader = Vue.$loading.show();

      let formData = new FormData();
      formData.append("avatar", event.target.files[0]);

      this.$apiService
        .updateProfileImage(formData)
        .then((response) => {
          loader.hide();
          let res = response.data;

          if (response.status == 200) {
            this.$user.avatar = res.data.avatar;
            this.$eventBus.$emit("profile-avatar-change-event", res.data.avatar);
            this.$toastMessage.success(res.message);
            this.onLoadGamification();
          }
        })
        .catch((e) => {
          loader.hide();
        });
    },
    getExpertise() {
      let loader = Vue.$loading.show();

      this.$apiService
        .getExpertise()
        .then((response) => {
          loader.hide();
          let res = response.data.data;
          this.expertiseList = res.map((val) => {
            return {
              value: val.id,
              text: val.expertise_name,
            };
          });
        })
        .catch((e) => {
          loader.hide();
        });
    },
    prepareExpertise(from) {
      if (from instanceof Array) {
        this.$user.expertise = from.map((val) => {
          return {
            value: val.expertise_id,
            text: val.expertise_name,
            id: val.pivot.id,
          };
        });
      }
    },
    getDoctorExpertise(doctorId) {
      let loader = Vue.$loading.show();
      this.$apiService
        .getDoctorExpertise(doctorId)
        .then((response) => {
          loader.hide();
          let res = response.data.data;
          this.prepareExpertise(res.expertise);
        })
        .catch((e) => {
          loader.hide();
        });
    },
    removeDoctorExpertise(id) {
      let loader = Vue.$loading.show();

      this.$apiService
        .removeDoctorExpertise(id)
        .then((response) => {
          loader.hide();
          if (response.data.success) {
            this.$toastMessage.success(response.data.message);
            this.getExpertise();
          }
        })
        .catch((e) => {
          loader.hide();
        });
    },
    addDoctorExpertise(data) {
      let loader = Vue.$loading.show();
      let sendData = {
        expertise_id: data.value,
        doctor_id: this.$user.id,
      };
      console.log(data);
      this.$apiService
        .addDoctorExpertise(sendData)
        .then((response) => {
          let res = response.data;
          let insertedId = res.data.id;
          if (insertedId > 0) {
            this.$user.expertise.push(res.data);
          }
          loader.hide();
          if (response.data.success) {
            this.$toastMessage.success(res.message);
            this.getExpertise();
            this.onLoadGamification();
          }
        })
        .catch((e) => {
          loader.hide();
          if (e.response.status === 400) {
            this.$toastMessage.error(e.response.data.data.message);
          }
        });
    },
    onLoadGamification() {
      this.loadGamification();
      if (!this.profileWasComplete && this.$user.profile_is_complete) {
        this.$eventBus.$emit("profile-is-complete");
      }
    },
  },
  mounted() {
    this.profileWasComplete = this.$user.profile_is_complete === true;
    this.getExpertise();
    this.defaultAvatar = this.$apiService.getDefaltAvatar();
    this.$nextTick(function () {
      if (this.$refs && this.$refs.avatar) {
        this.$refs.avatar.addEventListener("error", function () {
          this.$refs.avatar.src = this.defaultAvatar;
        });
      }
    });
  },
};
</script>

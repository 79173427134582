<template>
  <main class="main-contant">
    <div class="clearfix">
      <h1 class="page-title float-left">
        Question
      </h1>
    </div>
    <div class="page-content">
      <h2 class="question-head">
        {{ questionInfo.question }} ({{ this.flag }})
      </h2>

      <div class="row">
        <div class="col-md-4">
          <div class="mt-4">
            <div class="question-form">
              <h5>Question Info</h5>
              <div
                class="list-group"
                style="border: none"
              >
                <a
                  v-for="(normalizedInfo, index) in normalizedQuestionInfo"
                  :key="index"
                  href="javascript:;"
                  class="list-group-item list-group-item-action flex-column align-items-start"
                >
                  <div class="d-flex w-100 justify-content-between">
                    <h5
                      style="font-size: 1rem"
                      class="mb-1"
                    >
                      {{ normalizedInfo.title }}
                    </h5>
                  </div>
                  <p>
                    {{ normalizedInfo.normalizedValue || normalizedInfo.value }}
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <h5 class="mt-4 ">
            Response
          </h5>

          <div class="card-wrap mt-1">
            <treeselect
              v-model="filteredCategories"
              clearable
              searchable
              search-nested
              placeholder="Add categories"
              class="form-control"
              style="padding: 0px"
              :multiple="true"
              :options="normalizedCategories"
              :limit="3"
              :disabled="questionInfo.is_published || !questionInfo.claimed_by"
            />
            <p class="m-0">
              (<i>Add as many categories as will apply</i>)
            </p>
          </div>

          <div class="card-wrap mt-4">
            <div class="form-group">
              <div class="row mb-2">
                <div class="col-md-5">
                  <label>Answer</label>
                </div>
              </div>
              <textarea
                type="text"
                placeholder="Answer"
                class="form-control"
                rows="5"
                v-model="questionInfo.content"
                :disabled="
                  questionInfo.is_published || !questionInfo.claimed_by
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Vue from "vue";
import Treeselect from "@riophae/vue-treeselect";

export default {
  components: { Treeselect },
  data() {
    return {
      categoriesList: [],
      filteredCategories: [],
      value: [],
      questionId: "",
      questionInfo: {},
      defAnswer: null,
      defaultAnswer1:
        "Thank you for your question. Unfortunately, you did not supply enough information for our doctors to properly respond. Please ask again, and make sure to include more details (but make sure you do not identify yourself).",
      defaultAnswer2:
        "Thank you for your question. However, we cannot answer your question at this time. Please try submitting your question again, and keep in mind the following: 1. AskDrFIGO.org is bound by the local laws in your country 2. AskDrFIGO.org is a place to ask questions about Sexual and Reproductive Health and Rights (SRHR)"
    };
  },
  computed: {
    normalizedQuestionInfo() {
      return [
        {
          title: "Gender",
          value: this.questionInfo.gender_value
        },
        {
          title: "Age",
          value: this.questionInfo.age_value
        },
        {
          title: "Are You Pregnant Right Now",
          value: this.questionInfo.is_pregnant,
          normalizedValue: this.booleanNormalizer(this.questionInfo.is_pregnant)
        },
        {
          title: "Are You Sexually Active Right Now",
          value: this.questionInfo.is_sexually_active,
          normalizedValue: this.booleanNormalizer(
            this.questionInfo.is_sexually_active
          )
        },
        {
          title: "How Much Education Have You Had",
          value: this.questionInfo.education_value
        },
        {
          title: "Have You Ever Seen A Women’s Health Doctor",
          value: this.questionInfo.ever_seen_doctor,
          normalizedValue: this.booleanNormalizer(
            this.questionInfo.ever_seen_doctor
          )
        },
        {
          title: "Are You In A Relationship",
          value: this.questionInfo.in_relationship,
          normalizedValue: this.booleanNormalizer(
            this.questionInfo.in_relationship
          )
        }
      ];
    },
    normalizedCategories() {
      return this.categoriesList.length > 0
        ? this.categoriesList.map((c) => this.normalizeCategory(c))
        : [];
    }
  },
  methods: {
    booleanNormalizer(value) {
      return value == null ? "N/A" : value ? "Yes" : "No";
    },
    getNotificationById() {
      let loader = Vue.$loading.show();

      let params = {
        locale: this.$selectedCountry.locale
      };
      this.$apiService
        .getNotificationById(this.questionId, params)
        .then((response) => {
          loader.hide();

          let res = response.data;
          if (res.success) {
            this.questionInfo = res.data;
            if (this.questionInfo.categories.length > 0) {
              this.filteredCategories =
                this.questionInfo.categories.split(", ");
              this.filteredCategories = this.filteredCategories.map(function (
                x
              ) {
                return parseInt(x, 10);
              });
            } else {
              this.filteredCategories = [];
            }
          }
        })
        .catch((e) => {
          loader.hide();
          this.errors.push(e);
        });
    },
    onSubmit(isPublishFlag) {
      if (
        this.questionInfo.content == undefined ||
        this.questionInfo.content == ""
      ) {
        this.$toastMessage.error("Answer is required");
        return false;
      }

      if (isPublishFlag == 1) {
        if (this.value.length == 0) {
          this.$toastMessage.error("Categories is required");
          return false;
        }
      }

      let loader = Vue.$loading.show();

      let sendData = {
        content: this.questionInfo.content,
        is_published: isPublishFlag,
        category_id: this.value.join(", ")
      };

      this.$apiService
        .submitAnswer(sendData, this.questionId)
        .then((response) => {
          loader.hide();
          let res = response.data;

          if (response.status == 200) {
            this.$toastMessage.success(res.message);
            this.getNotificationById();
          }
        })
        .catch((e) => {
          loader.hide();
          if (e.response.status === 400) {
            this.$toastMessage.error(e.response.data.data.message);
          }
        });
    },
    getCategory() {
      let params = {
        locale: this.$selectedCountry.locale
      };

      this.$apiService
        .getCategory(params)
        .then((response) => {
          let res = response.data.data;
          if (response.status == 200) {
            this.categoriesList = res;
          }
        })
        .catch((e) => {
          this.categoriesList = [];
        });
    },
    redirectToGuidelines() {
      this.$router.push("/guidelines?redirect=" + window.location.pathname);
    },
    normalizeCategory(category) {
      const result = {
        id: category.id,
        label: category.title
      };
      if (category.children && category.children instanceof Array) {
        result.children = category.children.map((c) =>
          this.normalizeCategory(c)
        );
      }
      return result;
    },
    defAnswerSelected() {
      const answer = (this.questionInfo.boilerplate_answers || []).find(
        (a) => (a.id = this.defAnswer)
      );
      if (answer) {
        this.questionInfo.content =
          this.questionInfo.content != null && this.questionInfo.content != ""
            ? this.questionInfo.content + " " + answer.content
            : answer.content;
      }
    }
  },
  mounted() {
    this.questionId = this.$route.params.questionId;
    this.flag = this.$route.params.flag;
    if (this.flag == 1) {
      this.flag = "Suggested Question Notification";
    }
    if (this.flag == 2) {
      this.flag = "8 Hours Remind Notification";
    }
    if (this.flag == 3) {
      this.flag = "20 Hours Remind Notification";
    }
    if (this.flag == 4) {
      this.flag = "Draft Notification";
    }

    this.getNotificationById();
    this.getCategory();
  },
  watch: {
    filteredCategories(value) {
      this.value = value;
    }
  }
};
</script>

<style scoped>
.use-answer-btn {
  background: #53bbb4;
  color: #fff;
  padding: 2px 8px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 95%;
  text-transform: none;
}
</style>

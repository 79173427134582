<template>
  <div v-if="question instanceof Object && model.id > 0">
    <b-button
      :class="{
        'drbtn btn-outline-primary ml-2': true,
        'claimed': isClaimed
      }"
      :id="`claim-modal-${model.id}`"
      :disabled="loading"
      @click="onToggleModal"
    >
      {{ buttonText }}
    </b-button>
    <b-modal
      centered
      title="Confirm"
      ref="modal"
      @ok="onHandleClaim"
    >
      {{ message }}
    </b-modal>
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    question: {
      type: Object,
      default: () => {},
    },
    onClaimHandler: {
      type: Function,
      default: () => {},
    },
    onUnClaimHandler: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      status: null,
      model: this.question,
    };
  },
  computed: {
    ...mapGetters(["$user"]),
    isClaimed() {
      return this.model.claimed_by == this.$user.id;
    },
    buttonText() {
      return this.isClaimed ? "Unclaim" : "Claim";
    },
    message() {
      return `Are you sure you want to ${
        this.isClaimed ? "unclaim" : "claim"
      } this.model?`;
    },
  },
  methods: {
    ...mapActions(["claimQuestion", "unclaimQuestion"]),
    onToggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["modal"].toggle(`#claim-modal-${this.model.id}`);
    },
    onHandleClaim() {
      if (this.isClaimed) {
        this.onUnclaim();
      } else {
        this.onClaim();
      }
    },
    onClaim() {
      this.loading = true;
      this.claimQuestion(this.model)
        .then((response) => {
          this.loading = false;
          let res = response.data;
          Object.assign(this.model, {
            claimed_by: this.$user.id,
            claimed_on: Date.now(),
          });

          this.$toastMessage.success(res.message);
          this.$emit("input", this.model.id);
          this.onClaimHandler(this.model);
        })
        .catch((e) => {
          this.loading = false;
          this.$toastMessage.error(e.response.data.data.message);
        });
    },
    onUnclaim() {
      this.unclaimQuestion(this.model)
        .then((response) => {
          let res = response.data;

          this.$toastMessage.success(res.message);
          Object.assign(this.model, {
            claimed_by: false,
            claimed_on: null,
          });
          this.onUnClaimHandler(this.model);
        })
        .catch((e) => {
          this.$toastMessage.error(e.response.data.data.message);
        });
    },
  },
};
</script>

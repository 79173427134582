<template>
  <div>
    <b-dropdown
      v-if="!isFlagged"
      no-caret
      variant="link"
      toggle-class="text-decoration-none"
      :disabled="loading"
    >
      <template #button-content>
        <i
          class="fa fa-flag pr-2"
          aria-hidden="true"
          style="color: #000; margin-right: 5px"
        /><i
          class="fa fa-ellipsis-v"
          aria-hidden="true"
        />
      </template>
      <b-dropdown-item
        v-for="(option, index) in options"
        v-b-modal="`flag-modal-${question.id}`"
        :key="index"
        @click="onSetReason(index + 1)"
      >
        {{ option }}
      </b-dropdown-item>
    </b-dropdown>
    <b-button
      v-else
      variant="link"
      :id="`flag-comment-modal-${model.id}`"
      @click="onSetQuestion"
      style="float: right; color: #ec5134; padding: 0rem 0.75rem; margin-right: 12px"
    >
      <i
        class="fa fa-flag"
        aria-hidden="true"
      />
    </b-button>

    <b-modal
      :id="`flag-modal-${question.id}`"
      centered
      title="Confirm"
      @ok="onHandleFlag"
    >
      Are you sure you want to flag this question?
    </b-modal>
    <b-modal
      v-model="commentModalShow"
      centered
      title="Comment"
      @ok="onHandleComment"
    >
      <textarea
        type="text"
        placeholder="Comment"
        class="form-control"
        rows="5"
        v-model="comment"
      />
    </b-modal>
    <b-modal
      centered
      ok-only
      ref="flagCommentModal"
    >
      <div v-if="model.docFlag || isFlaggedByMe">
        <h5>{{ isFlaggedByMe ? "You" : "Doctor" }} flagged this question as</h5>
        <p>{{ options[model.flag_status - 1] }}</p>
        <h5 v-if="model.flag_status == 3">
          comment:
        </h5>
        <p v-if="model.flag_status == 3">
          {{ model.comment }}
        </p>
      </div>
      <div v-if="model.adminFlag">
        <hr>
        <h5>
          Admin
          {{ model.accepted_status == 0 ? "Rejected" : "Accepted" }}
          the flag
        </h5>
        <h5 v-if="model.admin_comment != null">
          Comment:
        </h5>
        <div
          v-html="model.admin_comment"
          v-if="model.admin_comment != null"
        />
      </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    flaggable: {
      type: Boolean,
      default: false,
    },
    question: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      commentModalShow: false,
      comment: "",
      status: null,
      options: [
        "Legal Implication",
        "Can not answer",
        "Insufficient Information",
        "Other",
      ],
      model: this.question,
    };
  },
  computed: {
    ...mapGetters(["$user"]),
    isFlagged() {
      return this.isFlaggedByMe || this.model.docFlag || this.model.adminFlag;
    },
    isFlaggedByMe() {
      return this.$user.id == this.model.flagged_by;
    },
  },
  methods: {
    ...mapActions(["flagQuestion"]),
    onToggleFlagCommentModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["flagCommentModal"].toggle(`#flagged-comment-modal-${this.model.id}`);
    },
    onSetReason(value) {
      this.status = value;
      if (value == 3) {
        this.commentModalShow = true;
      }
      this.$emit("input", value);
    },
    onSetQuestion() {
      this.onToggleFlagCommentModal();
      this.$emit("set-question", this.model);
    },
    onHandleComment(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.comment == undefined || this.comment == "") {
        this.$toastMessage.error("Comment is required");
        return false;
      }
      this.commentModalShow = false;
      this.onFlag();
    },
    onHandleFlag() {
      if (this.selectForFlag == 3) {
        this.commentModalShow = true;
      } else {
        this.onFlag();
      }
    },
    onFlag() {
      this.loading = true;
      let sendData = {
        flag_status: this.status,
        comment: this.comment,
        id: this.model.id,
      };

      this.flagQuestion(sendData)
        .then((response) => {
          let res = response.data;
          if (response.status == 200) {
            this.loading = false;
            this.$toastMessage.success(res.message);
            Object.assign(this.model, {
              flag_status: this.status,
              flagged_by: this.$user.id,
              flagged_at: Date.now(),
            });
            this.$emit("flagged", true);
          }
        })
        .catch((e) => {
          if (e.response.status === 400) {
            this.$toastMessage.error(e.response.data.data.message);
          }
        });
    },
  },
};
</script>

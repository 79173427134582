<template>
  <aside class="main-nav">
    <div class="d-block d-lg-none py-3 px-3 mini-logo-bar">
      <router-link
        class="navbar-brand m-0 p-0"
        :to="{name: 'Questions'}"
      >
        <img
          src="../assets/images/mini-logo.png"
          class="d-block d-lg-none"
          width="50"
          alt="site-logo"
        >
      </router-link>
      <b-button class="navbar-toggler float-right">
        <img
          src="../assets/images/close.png"
          width="16"
          class="open-menu"
          alt=""
        >
      </b-button>
    </div>
    <div class="sidebar">
      <ul
        class="menu-list list-unstyled"
        id="menu"
      >
        <li>
          <!-- <b-button
            class="list-item"
            data-toggle="collapse"
            aria-expanded="true"
            aria-controls="collapseOne"
          > -->
          <b-button
            v-b-toggle.queue
            :class="{
              'list-item': true,
              active: $route.name === 'Questions'
            }"
            id="queue-menu"
          >
            <img
              src="../assets/images/question.png"
              width="16"
              alt="question"
            >
            Queue
          </b-button>
          <b-collapse
            visible
            class="list-unstyled submenu collapse"
            id="queue"
            aria-labelledby="question"
            data-parent="#menu"
          >
            <b-list-group flush>
              <b-list-group-item
                active-class="active"
                :to="{name: 'Questions'}"
                :active="$route.name === 'Questions'"
              >
                New Questions
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
        </li>
        <li>
          <!-- <b-button
            class="list-item"
            data-toggle="collapse"
            aria-expanded="true"
            aria-controls="collapseOne"
          > -->
          <b-button
            v-b-toggle.questions-menu
            id="question-menu"
            :class="{
              'list-item': true,
              active: routeNames.indexOf($route.name) > -1
            }"
          >
            <img
              src="../assets/images/question.png"
              width="16"
              alt="question"
            >
            Questions
          </b-button>
          <b-collapse
            visible
            class="list-unstyled submenu collapse"
            id="questions-menu"
            aria-labelledby="question"
            data-parent="#menu"
          >
            <b-list-group-item
              v-for="(item, index) in routes.questions"
              active-class="active"
              :key="index"
              :to="{name: item.name}"
              :active="$route.name === item.name"
            >
              <span id="title"> {{ item.label }} </span>
              <b-badge
                v-if="item.count !== false"
                pill
                :variant="item.count > 0 ? 'info' : ''"
              >
                {{ item.count || 0 }}
              </b-badge>
            </b-list-group-item>
          </b-collapse>
        </li>
        <li class="nav-btn">
          <router-link
            class="list-item collapsed"
            :to="{name: 'Notifications'}"
          >
            <img
              src="../assets/images/notification.png"
              width="16"
              alt="Notification"
            >
            Notifications
          </router-link>
        </li>
        <li class="nav-btn">
          <router-link
            class="guidelines-btn list-item collapsed text-primary"
            :to="{name: 'Checklist'}"
          >
            <b-icon
              icon="info-circle"
              class="mr-2"
            />Guidelines
          </router-link>
        </li>
        <!-- <li class="nav-btn collapse-menu">
          <a
            class="list-item collapsed hand-pointer"
            @click="openMenu()"
          >
            <i class="fa fa-chevron-circle-left" />
            Collapse Menu
          </a>
        </li> -->
      </ul>
    </div>
  </aside>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Navigation",
  data() {
    return {
      menuShow: true
    };
  },
  computed: {
    ...mapGetters(["$user"]),
    routeNames() {
      return this.routes.questions
        .map((q) => q.name)
        .concat(["MyAnsweredQuestion", "AnsweredQuestion"]);
    },
    routes() {
      return {
        questions: [
          {
            name: "AnsweredQuestions",
            label: "All Answered Questions",
            count: false
          },
          {
            name: "FlaggedQuestions",
            label: "All Flagged Questions",
            count: false
          },
          {
            name: "MyClaimedQuestions",
            label: "My Claimed Questions",
            count: this.$user.claimed_questions_count
          },
          {
            name: "MyAnsweredQuestions",
            label: "My Answered Questions",
            count: this.$user.answered_questions_count
          },
          {
            name: "MyFlaggedQuestions",
            label: "My Flagged Questions",
            count: this.$user.flagged_questions_count
          }
        ]
      };
    }
  },
  mounted: function () {
    let currentUrl = window.location.pathname;
  },
  methods: {
    ...mapActions(["logout"]),
    closeMenu() {
      document.getElementsByTagName("body")[0].classList.remove("open-menu");
    },
    toggle() {
      if (this.menuShow) {
        this.menuShow = false;
        document.getElementById("collapseOne").classList.add("show");
        document.getElementById("question-menu").classList.add("active");
        let currentUrl = window.location.pathname;
        if (!currentUrl.includes("question")) {
          this.$router.push({ name: "Questions" });
        }
      } else {
        this.menuShow = true;
        document.getElementById("collapseOne").classList.remove("show");
        document.getElementById("question-menu").classList.remove("active");
      }
    },
    closeToggle() {
      this.menuShow = true;
      document.getElementById("collapseOne").classList.remove("show");
      document.getElementById("question-menu").classList.remove("active");
    },
    openMenu() {
      let bodyElement = document.getElementsByTagName("body")[0];
      if (bodyElement.classList.contains("open-menu")) {
        bodyElement.classList.remove("open-menu");
        return;
      }
      bodyElement.classList.add("open-menu");
      this.closeToggle();
    }
  }
};
</script>
<style>
span#claimedCount {
  background: red;
  border-radius: 30px;
  padding: 2px 4px;
  color: white;
  font-size: 10px;
  min-width: 22px;
  height: 18px;
  text-align: center;
  line-height: 12px;
  position: absolute;
  top: calc(50% - 7px);
  right: 20px;
}

aside.main-nav .sidebar .menu-list li .submenu li a {
  position: relative;
}

span#title {
  width: 90%;
  font-size: 15px;
}
</style>
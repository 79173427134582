import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		user: {},
		gamification: {
			profile: {},
		},
	},
	mutations: {
		setUser(state, user) {
			state.user = user;
		},
		removeUser(state) {
			state.user = {};
		},
		setGamification(state, data) {
			state.gamification = data;
		},
		incrementClaimedQuestions(state) {
			state.user.claimed_questions_count++;
		},
		decrementClaimedQuestions(state) {
			state.user.claimed_questions_count--;
		},
		incrementAnsweredQuestions(state) {
			state.user.answered_questions_count++;
		},
		decrementAnsweredQuestions(state) {
			state.user.answered_questions_count--;
		},
		incrementFlaggedQuestions(state) {
			state.user.flagged_questions_count++;
		},
	},
	actions: {
		getProfile() {
			return Vue.prototype.$apiService.getProfile();
		},
		setUser(context, user) {
			context.commit('setUser', user.data || user);
		},
		removeUser(context) {
			context.commit('removeUser');
		},
		loadGamification(context) {
			return Vue.prototype.$apiService.getGamification().then((response) => {
				context.commit('setGamification', response.data);
			});
		},
		flagQuestion(context, question) {
			console.log('[Store]: Flagging', question);
			return Vue.prototype.$apiService
				.flagQuestion(question)
				.then((response) => {
					context.commit('incrementFlaggedQuestions');
					return response;
				});
		},
		claimQuestion(context, question) {
			console.log('[Store]: Claiming', question);
			return Vue.prototype.$apiService
				.claimQuestion(question)
				.then((response) => {
					context.commit('incrementClaimedQuestions');
					return response;
				});
		},
		unclaimQuestion(context, question) {
			console.log('[Store]: UnClaiming', question);
			return Vue.prototype.$apiService
				.unclaimQuestion(question)
				.then((response) => {
					context.commit('decrementClaimedQuestions');
					return response;
				});
		},
		answerQuestion(context, question) {
			console.log('[Store]: Answering', question);
			return Vue.prototype.$apiService
				.submitAnswer(question, question.id)
				.then((response) => {
					context.commit('incrementAnsweredQuestions');
					return response;
				});
		},
		setGamification(context, data) {
			context.commit('setGamification', data.data || data);
		},
		logout() {
			console.log('[API]: Logging out');
			let loader = Vue.$loading.show();

			Vue.prototype.$apiService
				.logout({})
				.then((response) => {
					loader.hide();
					localStorage.clear();
					console.log('[API]: Logged out');
					window.location.href = '/';
				})
				.catch((e) => {
					loader.hide();
				});
		},
	},
	getters: {
		$user: (state) => state.user,
		$gamification: (state) => state.gamification,
		$selectedCountry: (state) =>
			JSON.parse(localStorage.getItem('selectedCountry')) || { locale: 'en' },
	},
	modules: {},
});

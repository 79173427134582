<template>
  <div class="container">
    <div class="user-login-wrap row p-3">
      <div class="card-wrap col-md-5 mx-auto add-question-wrap">
        <a class="navbar-brand text-center d-block mb-5 p-0"><img
          src="../assets/images/logo.png"
          width="150"
          alt="site-logo"
        ></a>

        <form action="">
          <div class="form-group mb-4">
            <label for="">Username</label>
            <input
              type="text"
              placeholder="Username"
              class="form-control"
              v-model="newUser.email"
            >
          </div>
          <div class="form-group mb-4">
            <label for="">Password</label>
            <input
              type="password"
              placeholder="Password"
              class="form-control"
              v-model="newUser.password"
              @keydown.enter="onSubmit()"
            >
          </div>
          <div class="form-group">
            <a
              class="drbtn btn btn-outline-primary"
              @click="onSubmit()"
              style="width: 100%"
            >Sign In</a>
          </div>
        </form>
        <router-link
          :to="{ name: 'ForgotPasword' }"
          class="forgotpass"
        >
          Forgot Password?
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "Login",
  data() {
    return {
      newUser: {},
    };
  },
  methods: {
    onSubmit() {
      // Trim whitespace in login credentials
      this.newUser.email = this.newUser.email.trim();

      if (this.newUser.email == undefined || this.newUser.email == "") {
        this.$toastMessage.error("Username is required");
        return false;
      }

      if (!this.$apiService.validateEmail(this.newUser.email)) {
        this.$toastMessage.error("Please enter valid email");
        return false;
      }

      if (this.newUser.password == undefined || this.newUser.password == "") {
        this.$toastMessage.error("Password is required");
        return false;
      }

      let loader = Vue.$loading.show();

      this.$apiService
        .login(this.newUser)
        .then((response) => {
          loader.hide();
          let res = response.data;

          if (response.status == 200) {
            localStorage.setItem("accessToken", res.data.token);
            localStorage.setItem("userId", res.data.userId);
            let countryList = res.data.countries.map((val) => {
              return {
                id: val.id,
                name: val.name + " - " + val.language,
                locale: val.locale,
              };
            });

            countryList = countryList.sort((a, b) =>
              a.name.localeCompare(b.name)
            );

            localStorage.setItem(
              "userDetails",
              JSON.stringify({
                name: res.data.name,
                country: countryList,
                avatar: res.data.avatar,
              })
            );
            // add new condition for expertise is not assign
            let expertiseCount = res.data.expertise_count;
            if (res.data.countries.length == 0) {
              localStorage.setItem("selectedCountry", JSON.stringify(null));
              this.$toastMessage.success(res.message);
              if (expertiseCount == 0) {
                this.$router.push({ name: "Expertise" });
              } else {
                this.$router.push({ name: "Checklist" });
              }
            } else {
              if (res.data.current_language != null) {
                localStorage.setItem(
                  "selectedCountry",
                  JSON.stringify(
                    countryList.find((l) => l.id === res.data.current_language)
                  )
                );
                this.$toastMessage.success(res.message);
                if (expertiseCount == 0) {
                  this.$router.push({ name: "Expertise" });
                } else {
                  this.$router.push({ name: "Checklist" });
                }
              } else {
                let selectedLang =
                  countryList.find((l) => l.locale.startsWith("en")) ||
                  countryList[0];

                this.$apiService
                  .updateSelectedLanguage(selectedLang.id)
                  .then((response) => {
                    localStorage.setItem(
                      "selectedCountry",
                      JSON.stringify(selectedLang)
                    );
                    this.$toastMessage.success(res.message);
                    if (expertiseCount == 0) {
                      this.$router.push({ name: "Expertise" });
                    } else {
                      this.$router.push({ name: "Checklist" });
                    }
                  });
              }
            }
          }
        })
        .catch((e) => {
          loader.hide();
          if (e.response?.status === 400) {
            this.$toastMessage.error(e.response.data.data.message);
          }
          console.warn(e);
        });
    },
  },
  created() {
    localStorage.clear();
  },
};
</script>

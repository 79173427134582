var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"main-nav"},[_c('div',{staticClass:"d-block d-lg-none py-3 px-3 mini-logo-bar"},[_c('router-link',{staticClass:"navbar-brand m-0 p-0",attrs:{"to":{name: 'Questions'}}},[_c('img',{staticClass:"d-block d-lg-none",attrs:{"src":require("../assets/images/mini-logo.png"),"width":"50","alt":"site-logo"}})]),_c('b-button',{staticClass:"navbar-toggler float-right"},[_c('img',{staticClass:"open-menu",attrs:{"src":require("../assets/images/close.png"),"width":"16","alt":""}})])],1),_c('div',{staticClass:"sidebar"},[_c('ul',{staticClass:"menu-list list-unstyled",attrs:{"id":"menu"}},[_c('li',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.queue",modifiers:{"queue":true}}],class:{
            'list-item': true,
            active: _vm.$route.name === 'Questions'
          },attrs:{"id":"queue-menu"}},[_c('img',{attrs:{"src":require("../assets/images/question.png"),"width":"16","alt":"question"}}),_vm._v(" Queue ")]),_c('b-collapse',{staticClass:"list-unstyled submenu collapse",attrs:{"visible":"","id":"queue","aria-labelledby":"question","data-parent":"#menu"}},[_c('b-list-group',{attrs:{"flush":""}},[_c('b-list-group-item',{attrs:{"active-class":"active","to":{name: 'Questions'},"active":_vm.$route.name === 'Questions'}},[_vm._v(" New Questions ")])],1)],1)],1),_c('li',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.questions-menu",modifiers:{"questions-menu":true}}],class:{
            'list-item': true,
            active: _vm.routeNames.indexOf(_vm.$route.name) > -1
          },attrs:{"id":"question-menu"}},[_c('img',{attrs:{"src":require("../assets/images/question.png"),"width":"16","alt":"question"}}),_vm._v(" Questions ")]),_c('b-collapse',{staticClass:"list-unstyled submenu collapse",attrs:{"visible":"","id":"questions-menu","aria-labelledby":"question","data-parent":"#menu"}},_vm._l((_vm.routes.questions),function(item,index){return _c('b-list-group-item',{key:index,attrs:{"active-class":"active","to":{name: item.name},"active":_vm.$route.name === item.name}},[_c('span',{attrs:{"id":"title"}},[_vm._v(" "+_vm._s(item.label)+" ")]),(item.count !== false)?_c('b-badge',{attrs:{"pill":"","variant":item.count > 0 ? 'info' : ''}},[_vm._v(" "+_vm._s(item.count || 0)+" ")]):_vm._e()],1)}),1)],1),_c('li',{staticClass:"nav-btn"},[_c('router-link',{staticClass:"list-item collapsed",attrs:{"to":{name: 'Notifications'}}},[_c('img',{attrs:{"src":require("../assets/images/notification.png"),"width":"16","alt":"Notification"}}),_vm._v(" Notifications ")])],1),_c('li',{staticClass:"nav-btn"},[_c('router-link',{staticClass:"guidelines-btn list-item collapsed text-primary",attrs:{"to":{name: 'Checklist'}}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"info-circle"}}),_vm._v("Guidelines ")],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <main class="main-contant">
    <div class="clearfix">
      <h1 class="page-title float-left">
        New Question Queue
        <b-btn
          class="guidelines-btn"
          variant="outline-primary"
          @click="checklistHandler()"
        >
          <b-icon
            icon="info-circle"
            class="mr-2"
          />Guidelines
        </b-btn>
      </h1>
    </div>
    <List
      is-suggested
      title="Suggested For You!"
    />
    <List />
  </main>
</template>

<script>
import List from "./List";

export default {
  name: "QuestionQueue",
  components: { List },
  methods: {}
};
</script>

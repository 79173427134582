<template>
  <div class="container">
    <div class="user-login-wrap row p-3">
      <div
        data-v-97462a74=""
        class="card-wrap col-md-7 mx-auto add-expertise-wrap"
      >
        <div class="card-heading">
          <h3>Doctor's Expertise</h3>
        </div>
        <!-- v-model="selected" -->
        <b-row>
          <b-button
            v-for="(expertise, index) in expertiseList"
            pill
            class="mr-2 my-1"
            :key="index"
            :variant="isSelected(expertise.value) ? 'success': 'light'"
            :disabled="expertise.loading"
            @click="toggleExpertise(index, expertise)"
          >
            {{ expertise.text }}
            <b-spinner
              v-if="expertise.loading"
              small
              label="Busy"
            />
            <b-icon
              v-else
              :icon="isSelected(expertise.value) ? 'x' : 'plus'"
              :variant="isSelected(expertise.value) ? 'light' : 'dark'"
            />
          </b-button>
        </b-row>
        <!-- <VSelect
          @input="add"
          :options="expertiseList"
          :disabled="disabled"
          :searchable="searchable"
          search-placeholder="Search Bar"
          search-not-found="No expertise found!"
          default-title="Select Expertise"
          class="expert-drp"
        />
        <div class="mt-3">
          <ul class="expert-list">
            <li
              v-for="(expertise, index) in selected"
              :key="index"
            >
              <span>
                {{ expertise.text }} <span
                  @click="remove(index,expertise.id)"
                  class="removeBtn"
                > <img src="../assets/images/xmark-solid.svg"> </span>
              </span>
            </li>
          </ul>
          <strong>{{ selected }}</strong>
      </div> -->
        <hr class="guidelines-break">
        <div class="row">
          <div class="col-md-8">
            <!-- <p class="m-0">
                If you have any questions/comments/concerns:
                <br>
                <span style="color:#53bbb4">feedback@askdrfigo.org</span>
              </p> -->
          </div>
          <div class="col-md-4">
            <a
              class="drbtn btn btn-outline-primary"
              @click="onSubmit()"
              style="width: 100%"
            >Next</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import VSelect from "vue-select-picker-bootstrap";
export default {
  name: "Expertise",
  components: {
    // VSelect,
  },
  data() {
    return {
      profileInfo: {
        avatar: null,
      },
      copyProfileInfo: {},
      isChangePassword: false,
      defaultAvatar: null,
      expertiseList: [],
      disabled: false,
      searchable: true,
      selected: [],
    };
  },
  computed: {
    ...mapGetters(["$user"]),
  },
  methods: {
    toggleExpertise(index, expertise) {
      if (this.isSelected(expertise.value)) {
        this.remove(index, expertise.value);
      } else {
        this.add(index, expertise);
      }
    },
    isSelected(id) {
      return (
        this.selected.findIndex(function (el, index) {
          return el.id === id;
        }) > -1
      );
    },
    remove(index, id) {
      return this.removeDoctorExpertise(index, id);
    },
    add(index, data) {
      // call insert api Here
      return this.addDoctorExpertise(index, data);
    },
    getProfile() {
      let loader = Vue.$loading.show();

      this.$apiService
        .getProfile()
        .then((response) => {
          loader.hide();

          let res = response.data.data;

          if (response.status == 200) {
            this.profileInfo = res;
            // get all expertise of doctor;
            let doctorId = res.id;
            this.selected = res.expertise.map((val) => {
              return {
                value: val.id,
                text: val.expertise_name,
                id: val.pivot.expertise_id,
              };
            });
            // this.getDoctorExpertise(doctorId);

            this.copyProfileInfo = this.parseJSON(
              JSON.stringify(this.profileInfo)
            );
          }
        })
        .catch((e) => {
          loader.hide();
          this.profileInfo = { avatar: null };
        });
    },
    getExpertise() {
      let loader = Vue.$loading.show();

      this.$apiService
        .getExpertise()
        .then((response) => {
          loader.hide();
          let res = response.data.data;
          this.expertiseList = res.map((val) => {
            return {
              loading: false,
              value: val.id,
              text: val.expertise_name,
            };
          });
        })
        .catch((e) => {
          loader.hide();
        });
    },
    getDoctorExpertise(doctorId) {
      let loader = Vue.$loading.show();
      this.$apiService
        .getDoctorExpertise(doctorId)
        .then((response) => {
          loader.hide();
          let res = response.data.data;
          this.selected = res.expertise.map((val) => {
            return {
              value: val.expertise_id,
              text: val.expertise_name,
              id: val.pivot.id,
            };
          });
        })
        .catch((e) => {
          loader.hide();
        });
    },
    removeDoctorExpertise(index, id) {
      this.expertiseList[index].loading = true;
      this.$apiService
        .removeDoctorExpertise(id)
        .then((response) => {
          console.log("Removing", index, "from", this.selected);
          this.selected.splice(
            this.selected.findIndex((s) => s.id == id),
            1
          );
          this.$toastMessage.success(response.data.message);
        })
        .catch((e) => {
          if (e.response.status === 400) {
            this.$toastMessage.error(e.response.data.data.message);
          }
        })
        .finally(() => {
          this.expertiseList[index].loading = false;
        });
    },
    addDoctorExpertise(index, data) {
      let sendData = {
        expertise_id: data.value,
        doctor_id: this.profileInfo.id,
      };
      console.log(data);
      this.expertiseList[index].loading = true;
      this.$apiService
        .addDoctorExpertise(sendData)
        .then((response) => {
          let res = response.data;
          let insertedId = res.data.id;
          if (insertedId > 0) {
            this.selected.push({
              value: data.value,
              text: data.text,
              id: insertedId,
            });
          }
          this.$toastMessage.success(res.message);
        })
        .catch((e) => {
          if (e.response.status === 400) {
            this.$toastMessage.error(e.response.data.data.message);
          }
        })
        .finally(() => {
          this.expertiseList[index].loading = false;
        });
    },
    onSubmit() {
      this.$router.push({ name: "Checklist" });
    },
  },
  created() {
    this.selected = (this.$user.expertise || []).map((val) => {
      return {
        value: val.id,
        text: val.expertise_name,
        id: val.pivot.expertise_id,
      };
    });
  },
  mounted() {
    this.getExpertise();
  },
};
</script>

<style scoped>
.guidelines-title {
  font-size: 18px;
  color: #53bbb4;
}

.guidelines-break {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-color: #53bbb4;
}

.add-expertise-wrap {
  padding: 25px;
}
</style>

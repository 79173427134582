import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../components/Login';
import Forgot from '../components/Forgot';
import Reset from '../components/Reset';
import Checklist from '../components/Checklist';
import Expertise from '../components/Expertise';
import Layout from '../views/Layout';
import Profile from '../views/pages/Profile';
import Answer from '../views/pages/Answer';
import NotificationsIndex from '../views/pages/Notifications/index';
import Notification from '../views/pages/Notifications/Notification';
import Notifications from '../views/pages/Notifications/Notifications';
import QuestionsIndex from '../views/pages/Queue/index.vue';
import Questions from '../views/pages/Queue/New';
import AnsweredQuestionsIndex from '../views/pages/Queue/Answered/index';
import AnsweredQuestions from '../views/pages/Queue/Answered/Answered';
import FlaggedQuestions from '../views/pages/Queue/Flagged';
import MyQuestionQueue from '../views/pages/MyQueue';
import MyClaimedQuestions from '../views/pages/MyQueue/Claimed';
import MyAnsweredQuestionsIndex from '../views/pages/MyQueue/Answered/index';
import MyAnsweredQuestions from '../views/pages/MyQueue/Answered/Answered';
import MyFlaggedQuestions from '../views/pages/MyQueue/Flagged';
import store from '../store';

Vue.use(VueRouter);

function guardMyroute(to, from, next) {
	let isAuthenticated = false;
	if (
		localStorage.getItem('accessToken') &&
		localStorage.getItem('userDetails')
	)
		isAuthenticated = true;
	else isAuthenticated = false;

	if (isAuthenticated) {
		next({ name: 'Questions' });
	} else {
		next();
	}
}

function checkToken(to, from, next) {
	let isAuthenticated = false;
	if (localStorage.getItem('accessToken')) isAuthenticated = true;
	else isAuthenticated = false;

	if (isAuthenticated) {
		// This should run as long as there are credentials not only if there are credentials and a token
		next();
	} else {
		next('/');
	}
}

const routes = [
	{
		path: '/',
		name: 'Login',
		beforeEnter: guardMyroute,
		meta: { requiresAuth: false },
		component: Login,
	},
	{
		path: '/forgot-password',
		name: 'ForgotPassword',
		meta: { requiresAuth: false },
		component: Forgot,
	},
	{
		path: '/reset-password/:token',
		name: 'ResetPassword',
		meta: { requiresAuth: false },
		component: Reset,
	},
	{
		path: '/guidelines',
		name: 'Checklist',
		component: Checklist,
		meta: { requiresAuth: true },
		beforeEnter: checkToken,
	},
	{
		path: '/expertise',
		name: 'Expertise',
		component: Expertise,
		meta: { requiresAuth: true },
		beforeEnter: checkToken,
	},
	{
		path: '',
		component: Layout,
		meta: { requiresAuth: true },
		beforeEnter: checkToken,
		children: [
			{
				path: '/questions',
				name: 'QuestionsIndex',
				meta: { requiresAuth: true },
				component: QuestionsIndex,
				children: [
					{
						path: '',
						name: 'Questions',
						meta: { requiresAuth: true },
						component: Questions,
					},
					{
						path: ':questionId/answer',
						name: 'Answer',
						meta: { requiresAuth: true },
						component: Answer,
					},
					{
						path: 'claimed/:questionId/answer',
						name: 'ClaimedAnswer',
						meta: { requiresAuth: true },
						component: Answer,
					},
					{
						path: 'answered',
						name: 'AnsweredQuestions',
						meta: { requiresAuth: true },
						component: AnsweredQuestionsIndex,
						children: [
							{
								path: '',
								name: 'AnsweredQuestions',
								meta: { requiresAuth: true },
								component: AnsweredQuestions,
							},
							{
								path: ':questionId/answer',
								name: 'AnsweredQuestion',
								meta: { requiresAuth: true },
								component: Answer,
							},
						],
					},
					{
						path: 'flagged',
						name: 'FlaggedQuestions',
						meta: { requiresAuth: true },
						component: FlaggedQuestions,
					},
				],
			},
			{
				path: '/profile',
				name: 'Profile',
				meta: { requiresAuth: true },
				component: Profile,
			},
			{
				path: 'my/questions',
				meta: { requiresAuth: true },
				component: MyQuestionQueue,
				children: [
					{
						path: 'answered',
						name: 'MyAnsweredQuestionsIndex',
						meta: { requiresAuth: true },
						component: MyAnsweredQuestionsIndex,
						children: [
							{
								path: '',
								name: 'MyAnsweredQuestions',
								meta: { requiresAuth: true },
								component: MyAnsweredQuestions,
							},
							{
								path: ':questionId/answer',
								name: 'MyAnsweredQuestion',
								meta: { requiresAuth: true },
								component: Answer,
							},
						],
					},
					{
						path: 'flagged',
						name: 'MyFlaggedQuestions',
						component: MyFlaggedQuestions,
					},
					{
						path: 'claimed',
						name: 'MyClaimedQuestions',
						meta: { requiresAuth: true },
						component: MyClaimedQuestions,
					},
				],
			},
			{
				path: '/notifications',
				name: 'NotificationsIndex',
				meta: { requiresAuth: true },
				component: NotificationsIndex,
				children: [
					{
						path: '',
						name: 'Notifications',
						meta: { requiresAuth: true },
						component: Notifications,
					},
					{
						path: ':questionId/:flag',
						name: 'Notification',
						meta: { requiresAuth: true },
						component: Notification,
					},
				],
			},
		],
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	},
});

router.beforeEach(async (to, from, next) => {
	if (to.matched.some((route) => route.meta.requiresAuth)) {
		if (!store.getters.$user.id) {
			await store.dispatch('getProfile');
		}
		return next();
	}

	return next();
});

export { router, store };

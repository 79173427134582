<template>
  <main class="main-contant">
    <div class="clearfix">
      <h1 class="page-title float-left">
        My Claimed Questions
        <b-btn
          class="guidelines-btn"
          variant="outline-primary"
          @click="checklistHandler()"
        >
          <b-icon
            icon="info-circle"
            class="mr-2"
          />Guidelines
        </b-btn>
        <br><small>(<i>Questions not answered in 24 hours will be released to the New Questions
          queue</i>)</small>
      </h1>
    </div>
    <div class="page-content">
      <div class="filter-wrap mt-4">
        <ul class="list-inline clearfix">
          <li class="list-inline-item">
            <select
              class="form-control"
              v-model="filter.date"
              @change="dateChange()"
            >
              <option :value="''">
                All Dates
              </option>
              <option
                v-for="(dateOption, index) of dateOptions"
                :key="index"
                :value="index + 1"
              >
                {{ dateOption }}
              </option>
            </select>
          </li>
          <li
            class="list-inline-item"
            v-if="filter.date == '8'"
          >
            <div>
              <b-form-datepicker
                id="example-datepicker"
                placeholder="Start"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
                v-model="filter.start_date"
              />
            </div>
          </li>
          <li
            class="list-inline-item"
            v-if="filter.date == '8'"
          >
            <div>
              <b-form-datepicker
                id="example-datepicker1"
                placeholder="End"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
                v-model="filter.end_date"
                :min="filter.start_date"
              />
            </div>
          </li>
          <li
            class="list-inline-item"
            style="max-width: 25vw; width: max-content"
          >
            <treeselect
              v-model="filteredCategories"
              clearable
              searchable
              search-nested
              placeholder="Filter by categories"
              class="form-control"
              style="margin-bottom: -0.8rem; padding: 0px"
              :multiple="true"
              :options="normalizedCategories"
              :limit="3"
            />
          </li>
          <li class="list-inline-item">
            <button
              class="drbtn btn btn-outline-primary filterbtn"
              @click="onSearch()"
            >
              Filter
            </button>
          </li>
          <li class="list-inline-item float-right">
            <ul class="llist-inline pl-0 clearfix m-0 secrch-wrap">
              <li class="list-inline-item float-right mr-0 secrch-btn">
                <button
                  class="drbtn btn btn-outline-primary"
                  @click="onSearch()"
                >
                  Search
                </button>
              </li>
              <li class="list-inline-item float-right mr-3 secrch-input">
                <input
                  v-model="filter.search"
                  type="text"
                  class="form-control"
                  @keydown.enter="onSearch"
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="card-wrap">
        <div
          class="table-responsive"
          v-if="questionList.length > 0"
        >
          <table class="table">
            <thead>
              <tr>
                <th>
                  <span
                    class="hand-pointer"
                    @click="orderByHandler('asc', 'question')"
                  >
                    Question
                    <i
                      class="fa fa-caret-up"
                      :class="
                        filter.sort_on == 'question' && filter.sort_type == 'asc'
                          ? 'sort-icon-active'
                          : ''
                      "
                    />
                    <i
                      class="fa fa-caret-down"
                      :class="
                        filter.sort_on == 'question' && filter.sort_type == 'desc'
                          ? 'sort-icon-active'
                          : ''
                      "
                    />
                  </span>
                </th>
                <th>Categories</th>
                <th>
                  <span
                    class="hand-pointer"
                    @click="orderByHandler('asc', 'created_at')"
                  >
                    Asked On
                    <i
                      class="fa fa-caret-up"
                      :class="
                        filter.sort_on == 'created_at' && filter.sort_type == 'asc'
                          ? 'sort-icon-active'
                          : ''
                      "
                    />
                    <i
                      class="fa fa-caret-down"
                      :class="
                        filter.sort_on == 'created_at' && filter.sort_type == 'desc'
                          ? 'sort-icon-active'
                          : ''
                      "
                    />
                  </span>
                </th>
                <th>
                  <span
                    class="hand-pointer"
                    @click="orderByHandler('asc', 'claimed_on')"
                  >
                    Claimed On
                    <i
                      class="fa fa-caret-up"
                      :class="
                        filter.sort_on == 'claimed_on' && filter.sort_type == 'asc'
                          ? 'sort-icon-active'
                          : ''
                      "
                    />
                    <i
                      class="fa fa-caret-down"
                      :class="
                        filter.sort_on == 'claimed_on' && filter.sort_type == 'desc'
                          ? 'sort-icon-active'
                          : ''
                      "
                    />
                  </span>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(question, index) of questionList"
                :key="index"
              >
                <td>
                  <a
                    href="javascript:;"
                    @click="answerScreen(question.id)"
                    class="ellipsis"
                  >
                    {{ question.question }}</a>
                  <span class="ellipsis">{{ question.content }}</span>
                </td>
                <td>{{ question.categories }}</td>
                <td>{{ question.created_at | formatDate }}</td>
                <td>{{ question.claimed_on | formatDate }}</td>
                <td>
                  <ClaimButton :question="question" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="text-center"
          v-if="questionList.length == 0"
        >
          <p>No Questions Available</p>
        </div>
        <div
          class="paginations"
          v-if="totalCount > page_size"
        >
          <p>Rows per page:</p>
          <select
            name="limit"
            v-model="page_size"
            @change="onLimitChange()"
          >
            <option
              v-for="pageSize in perPageOptions"
              :key="pageSize"
              :value="pageSize"
            >
              {{ pageSize }}
            </option>
          </select>
          <p>
            <span>{{ firstText }}</span>-<span>{{ secondText }}</span> of <span>{{ totalCount }}</span>
            <i
              class="fa fa-angle-left"
              @click="previousPage()"
            />
            <i
              class="fa fa-angle-right"
              @click="nextPage()"
            />
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import Treeselect from "@riophae/vue-treeselect";
import FiltersQuestions from "@/mixins/FiltersQuestions.vue";

export default {
  name: "MyClaimedQuestions",
  components: { Treeselect },
  mixins: [FiltersQuestions],
  data() {
    return {
      selectForClaim: "",
    };
  },
  computed: {
    ...mapGetters(["$user", "$selectedCountry"]),
  },
  methods: {
    getClaimedQuestion() {
      let params = {
        page: this.page,
        page_size: this.page_size,
        country: this.$selectedCountry.id,
        status_type: 1,
        locale: this.$selectedCountry.locale,
      };

      // if (this.filter.date.length > 0) {
      //   params.date = this.filter.date;
      // }
      if (this.filter.hasOwnProperty("date")) {
        params.date = this.filter.date;
      }

      if (this.filter.categories != 0) {
        params.categories = this.filter.categories;
      }

      if (this.filter.search.length > 0) {
        params.search = this.filter.search;
      }

      if (this.filter.date == "8") {
        params.start_date = this.filter.start_date;
        params.end_date = this.filter.end_date;
      }

      if (this.filter.hasOwnProperty("sort_on")) {
        params.sort_on = this.filter.sort_on;
      }

      if (this.filter.hasOwnProperty("sort_type")) {
        params.sort_type = this.filter.sort_type;
      }

      let loader = Vue.$loading.show();

      this.$apiService
        .getQuestions(params)
        .then((response) => {
          loader.hide();

          let res = response.data.data;

          if (response.status == 200) {
            this.questionList = res.data;
            this.totalCount = res.total;
            if (this.totalCount == 0) {
              this.firstText = 0;
              this.secondText = 0;
            } else {
              this.firstText = this.page * this.page_size - this.page_size + 1;
              if (this.questionList.length != this.page_size) {
                this.secondText = Math.abs(
                  this.page * this.page_size - this.page_size + this.questionList.length
                );
              } else {
                this.secondText = this.page * this.page_size;
              }
            }
          }
        })
        .catch((e) => {
          loader.hide();
        });
    },
    onLimitChange() {
      this.page = 1;
      this.getClaimedQuestion();
    },
    previousPage() {
      if (this.page > 1) {
        this.page = this.page - 1;
        this.getClaimedQuestion();
      }
    },
    nextPage() {
      if (this.page < Math.ceil(this.totalCount / this.page_size)) {
        this.page = this.page + 1;
        this.getClaimedQuestion();
      }
    },
    getCategory() {
      let params = {
        locale: this.$selectedCountry.locale,
      };

      this.$apiService
        .getCategory(params)
        .then((response) => {
          let res = response.data.data;

          if (response.status == 200) {
            this.categoriesList = res;
          }
        })
        .catch((e) => {
          this.categoriesList = [];
        });
    },
    onSearch() {
      if (this.filter.date === "8") {
        if (this.filter.start_date == undefined) {
          this.$toastMessage.error("Start Date is required");
          return false;
        }

        if (this.filter.end_date == undefined) {
          this.$toastMessage.error("End Date is required");
          return false;
        }
      }

      this.page = 1;
      this.getClaimedQuestion();
    },
    answerScreen(id) {
      this.$router.push({ name: "ClaimedAnswer", params: { questionId: id } });
    },
    orderByHandler(order, column) {
      if (this.filter.sort_on == column) {
        this.filter.sort_type = this.filter.sort_type == "asc" ? "desc" : "asc";
      } else {
        this.filter.sort_on = column;
        this.filter.sort_type = order;
      }
      this.getClaimedQuestion();
    },
    prepare() {
      this.resetData();
      this.getCategory();
      this.getClaimedQuestion();
    },
  },
  mounted() {
    this.prepare();
    this.$eventBus.$on("country-change-event", (Data) => {
      this.prepare();
    });
  },
  beforeDestroy() {
    this.$eventBus.$off("country-change-event");
  },
};
</script>

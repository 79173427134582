import axios from 'axios';
import DefaultImage from '../assets/images/profile-picture.png';

export default class APIService {
	constructor(store) {
		this.store = store;
	}

	getDefaltAvatar() {
		return DefaultImage;
	}

	validateEmail(email) {
		const re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).trim().toLowerCase());
	}

	login(data) {
		return axios.post('login', data);
	}

	forgotPassword(data) {
		return axios.post('forgot-password', data);
	}

	forgotTokenCheck(token) {
		return axios.get('password/validate-token/' + token);
	}

	resetPassword(data) {
		return axios.post('reset-password', data);
	}

	logout(data) {
		return axios.post('logout', data, {});
	}

	getProfileImageHeader() {
		return {
			'Content-Type':
				'multipart/form-data; charset=utf-8; boundary=' +
				Math.random().toString().substring(2),
		};
	}

	getQuestions(params) {
		return axios.get('question-lists', {
			params,
		});
	}

	getCategory(params) {
		return axios.get('categories-list', {
			params,
		});
	}

	claimQuestion(question) {
		console.log('[API]: Claiming', question.id);
		return axios.put('questions/claimed/' + question.id, question.params, {});
	}

	unclaimQuestion(question) {
		console.log('[API]: UnClaiming', question.id);
		return axios.put('questions/unclaimed/' + question.id, question.params, {});
	}

	flagQuestion(body) {
		return axios.post('flag/' + body.id, body, {});
	}

	getGamification() {
		return axios.get('gamification', {}).then((result) => {
			this.store.dispatch('setGamification', result.data);
			return result.data;
		});
	}

	getGamificationHistory() {
		return axios.get('gamification/history', {});
	}

	async getProfile() {
		const result = await axios.get('profile', {});
		this.store.dispatch('setUser', result.data);
	}

	updateProfile(body) {
		return axios.put('profile', body, {}).then((result) => {
			this.store.dispatch('setUser', result.data);
			return result;
		});
	}

	submitAnswer(body, id) {
		return axios.post('questions/answer/' + id, body, {});
	}

	getQuestionById(questionId, params) {
		return axios.get('questions/' + questionId, {
			params,
		});
	}

	getProfileOnReload() {
		return axios.get('profile', {}).then((result) => {
			this.store.dispatch('setUser', result.data);
			return result;
		});
	}

	updateProfileImage(body) {
		return axios
			.post('upload-media', body, {
				headers: this.getProfileImageHeader(),
			})
			.then((result) => {
				this.store.dispatch('setUser', result.data);
				return result;
			});
	}

	updateSelectedLanguage(countryId) {
		return axios.put('save-language/' + countryId, {}, {});
	}

	getExpertise() {
		return axios.get('expertise-list', {});
	}

	getDoctorExpertise(doctorId) {
		return axios.get('doctor-expertise-list?doctorId=' + doctorId, {});
	}

	removeDoctorExpertise(id) {
		return axios.delete('remove-expertise?id=' + id, {});
	}

	addDoctorExpertise(data) {
		return axios.post('add-expertise', data, {});
	}

	getNotifications(params) {
		return axios.get('notifications', {
			params,
		});
	}

	notificationFlag(body, id) {
		return axios.put('notification-flag/' + id, body, {});
	}

	getNotificationById(questionId, params) {
		return axios.get('notifications/' + questionId, {
			params,
		});
	}

	getSuggested(params) {
		return axios.get('suggestion-lists', {
			params,
		});
	}
	getFlagQuestionLists(params) {
		return axios.get('flagquestion-lists', {
			params,
		});
	}
}
